<template>
  <div>
    <b-card>
      <page-title v-if="titleStatus" :title="$t('owners.items')" />
      <!-- <module-actions v-on:search="search($event)" :label="$t('owners.create')" routeName="contributor.ownerCreate" /> -->
      <main-table
        :fields="fields"
        :list_url="'admin/owner'"
        :reloadData="reloadData"
        :params="filter"
        :createBtnLabel="$t('owners.create')"
        createRouteName="contributor.ownerCreate"
        :excelFields="excelFields"
        excelName="owners"
        :downloadSampleStatus="true"
        downloadSampleUrl="owner/excel-sample"
        :uploadFileStatus="true"
        uploadFileUrl="owner/excel"
      />
      <!-- <main-table :fields="fields" :items="items"></main-table> -->
    </b-card>
  </div>
</template>
<script>
import { core } from "../../../../config/pluginInit";
import OwnerFilter from "../models/OwnerFilter";
import searchMixin from "../../../../Mixins/searchMixin";

export default {
  mixins: [searchMixin],
  data() {
    return {
      filter: new OwnerFilter(),
      fields: [
        this.$t("global.selected"),
        // '#',
        { key: "id", label: this.$t("table.id"), sortable: true },
        { key: "name", label: this.$t("table.name"), sortable: true },
        {
          key: "silk_image",
          label: this.$t("participants.silkImage"),
          type: "image",
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          type: "actions",
          actions: [
            {
              text: this.$t("global.view"),
              icon: "fas fa-eye",
              color: "info",
              ifNavigate: true,
              routeName: "contributor.ownerView",
              routeParams: ["id"],
            },
            {
              text: this.$t("global.update"),
              icon: "fas fa-pen",
              color: "primary",
              ifNavigate: true,
              routeName: "contributor.ownerUpdate",
              routeParams: ["id"],
            },
            {
              text: this.$t("global.delete"),
              icon: "fas fa-trash-alt",
              color: "danger",
              showAlert: true,
              actionHeader: this.$t("global.delete"),
              titleHeader: this.$t("owners.item"),
              textContent: "name",
              url: "admin/owner",
              type: "delete",
            },
          ],
        },
      ],
      excelFields: {
        [this.$t("table.id")]: "id",
        [this.$t("table.name")]: "name",
        [this.$t("table.createdAt")]: "created_at",
      },
      items: [
        {
          id: "20",
          name: "Mohamed Ibrahim",
          created_at: "2022/10/21 20:30:15",
        },
        { id: "21", name: "Hossam ALi", created_at: "2022/10/22 20:30:15" },
        { id: "22", name: "Ali Ahmed", created_at: "2022/10/21 20:35:15" },
        { id: "23", name: "Ahmed Khalid", created_at: "2022/10/21 20:40:15" },
        { id: "24", name: "Mohamed Maher", created_at: "2022/10/21 20:30:12" },
      ],
    };
  },
  // methods: {
  //   showData (obj) {
  //     console.log(obj)
  //   }
  // },
  // created () {
  //   this.$root.$on('showData', this.showData)
  // },
  // beforeDestroy () {
  //   this.$root.$off('showData')
  // }
  mounted() {
    core.index();
  },
};
</script>
